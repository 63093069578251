<template>
  <v-menu
    offset-y
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        class="white--text ma-5"
        v-bind="attrs"
        v-on="on"
        style="margin:0 !important;"
        color="primary"
        block
        small
      >
        {{ value ? value : level ? level.toUpperCase() : '' }}
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="item in ['insular', 'comarcal', 'local']"
        :key="item"
        link
        @click="handleChange(item)"
      >
        <v-list-item-title v-text="item.toUpperCase()" style="text-align:center"></v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    level: null,
  }),
  watch: {
    value () {
      this.prepareLevel()
    },
  },
  mounted () {
    this.prepareLevel()
  },
  methods: {
    prepareLevel () {
      this.level = this.value ? this.value : 'insular'
    },
    handleChange (v) {
      this.level = v
      this.onChange(this.id, v)
    },
  },
}
</script>

