<template>
  <v-expansion-panels
    focusable 
    v-if="menu"
    class="turismo"
  >
    <draggable
      v-model="menu"
      :draggable="'.itemDraggable' + id"
      style="width:100%;"
      v-bind="dragOptions"
      :animation="200"
      group="description"
      ghostClass="ghost"
      :disabled="!(activeDraggable === id || activeDraggable === '-1')"
      @start="handleSortStart(id)"
      @end="handleSortItems(menu)"
    >
        <v-expansion-panel
          v-for="(item) in menu"
          :key="item.ID"
          v-bind:class="'itemDraggable' + id"
        >
        <v-expansion-panel-header>
          <v-layout wrap>
            <v-flex xs3 style="margin: auto 0;">
              <v-icon
                v-if="isRequirePremission(permissions)"
                :color="item.Required ? '#000' : '#CCCCCC'"
                @click.stop.prevent="onRequire(item)"
                style="padding-right:10px"
              >
                {{ item.Required ? 'mdi-lock' : 'mdi-lock-open-variant'}}
              </v-icon>
              <v-icon
                v-else
                :color="item.Required ? '#00000080' : '#CCCCCC'"
                style="padding-right:10px;cursor: default"
              >
                {{ item.Required ? 'mdi-lock' : 'mdi-lock-open-variant'}}
              </v-icon>
              <v-icon
                v-if="isCheckPermission(item.Required, 'hideall', permissions, item.CW)"
                :color="item.Hide ? 'red' : 'green'" 
                @click.stop.prevent="handleHide(item.ID, item.Hide ? false : true)" 
                style="padding-right:10px"
              >
                mdi-folder-eye
              </v-icon>
              <v-icon
                v-if="isCheckPermission(item.Required, 'hideworkspace', permissions, item.CW)"
                :color="item.Hide ? '#ccc' : item.HideWorkspace ? 'red' : 'green'" 
                @click.stop.prevent="handleHideWorkspace(item)" 
                style="padding-right:10px"
              >
                mdi-eye
              </v-icon>
              <v-icon
                v-if="isCheckPermission(item.Required, 'update', permissions, item.CW)"
                @click.stop.prevent="onEdit(item)"
                style="padding-right:10px"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                v-if="isCheckPermission(item.Required, 'create', permissions)"
                @click.stop.prevent="onAddMenu(item.ID)"
                style="padding-right:10px"
              >
                mdi-plus
              </v-icon>
            </v-flex>
            <v-flex xs4>
              <p style="line-height:1.2;padding-top:10px;" v-html="prepareTitle(item, locale)" />
              <p v-if="isDebug" style="line-height:0">{{item.ID}} <v-icon small @click.stop.prevent="handleCopyID(item.ID)">mdi-content-copy</v-icon></p>
            <!--v-flex xs3>
              <v-btn
                @click.stop.prevent="handleShowID(item.ID)"
                x-small
                color="red"
              >
                {{item.ID}}
              </v-btn>
            </v-flex-->
            </v-flex>
            <v-flex xs2>
              <convert-to-owner 
                v-if="permissions.indexOf('4eb0204e-8480-4fa1-990e-5464785a04d9') >= 0 && workspaceID !== 'f0550a87-3358-4876-bf24-0ac33e9a93cf'"
                :workspaceID="workspaceID"
                :editedID="item.ID"
                :cw="{
                  ID: item.CW,
                  Name: item.WorkspaceName
                }"
                :locale="locale"
                :onUpdate="handleForceUpdate"
              />
            </v-flex>
            <v-flex xs3 v-if="!isMain">
              <menu-level :id="item.ID" :value="item.Level" :onChange="handleChangeLevel" />
            </v-flex>
          </v-layout>
        </v-expansion-panel-header>
          <v-expansion-panel-content style="padding: 0 !important;">
            <v-row
              align="center"
              style="margin:0px;"
            >
              <v-col 
                v-if="isCheckPermission(item.Required, 'create', permissions)"
                md="auto" style="min-width: 50px;cursor: pointer;" @click="onAddMenu(item.ID)"
              >
                <v-icon>mdi-plus</v-icon>
              </v-col>
              <v-col 
                v-else
                md="auto" style="min-width: 50px;"
              >
              </v-col>
              <v-col style="padding: 0;" >
                <menus 
                  :id="item.ID" 
                  :showAll="showAll" 
                  :onEdit="onEdit" 
                  :onRequire="onRequire" 
                  :permissions="permissions" 
                  :updateData="updateData"
                  :onAddMenu="onAddMenu"
                  :workspaceID="workspaceID"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
    </draggable>
  </v-expansion-panels>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
import turismo from '@/services/turismo'
import draggable from 'vuedraggable'
import Menus from './Menus'
import MenuLevel from './MenuLevel'
import ConvertToOwner from './ConvertToOwner'
//import MenuForm from './MenuForm'
export default {
  name: 'menus',
  components: {
    draggable,
    Menus,
    MenuLevel,
    ConvertToOwner,
    //MenuForm,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    showAll: {
      type: Boolean,
      required: true,
    },
    onEdit: {
      type: Function,
      required: true,
    },
    onAddMenu: {
      type: Function,
      required: true,
    },
    onRequire: {
      type: Function,
      default: null,
    },
    permissions: {
      type: Array,
      default: null,
    },
    updateData: {
      type: Object,
      default: null,
    },
    workspaceID: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      default: 'es',
    },
  },
  data: () => ({
    menu: null,
    activeDraggable: '-1',
    defaultItem: {
      ID: null,
      Title: {
        es: '',
        en: '',
        fr: '',
        de: ''
      },
      Description: {
        es: '',
        en: '',
        fr: '',
        de: ''
      },
      ListDetailChildrens: false,
      GoToDetailDirect: false,
      CustomPage: null,
    }
  }),
  computed: {
    isDebug () {
      return this.$route.query.debug
    },
    isMain () {
      return this.workspaceID === 'f0550a87-3358-4876-bf24-0ac33e9a93cf'
    },
    dragOptionsDisabled() {
      return {
        animation: 200,
        group: "description",
        disabled: true,
        ghostClass: "ghost"
      }
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    },
  },
  watch: {
    showAll () {
      this.handleGetData()
    },
    'updateData.lmd' () {
      if (this.id === this.updateData.id) this.handleGetData()
      //if (this.menu && this.menu.filter(x => this.updateData.ids.indexOf(x.ID) >= 0).length > 0) this.handleGetData()
    },
    workspaceID () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    isRequirePremission (v) {
      return turismo.checkRequirePermission(v)
    },
    handleForceUpdate () {
      this.handleGetData()
    },
    isCheckPermission (required, action, v, cw) {
      if (action === 'create')
        return required ? false : turismo.checkCreatePermission(v)
      else {
        if (required && !turismo.checkRequirePermission(v)) return false

        if (action === 'update')
          return turismo.checkUpdatePermission(v, cw === this.workspaceID)
        if (action === 'hideall')
          return turismo.checkHideAllPermission(v)
        if (action === 'hideworkspace')
          return turismo.checkHidePermission(v)
         /* 
        if (action === 'hide')
          return turismo.checkHidePermission(v)
        if (action === 'delete')
          return turismo.checkDeletePermission(v)
          */
      }
    },
    prepareTitle(v, locale) {
      let aux = this.workspaceID === 'f0550a87-3358-4876-bf24-0ac33e9a93cf' && this.workspaceID !== v.CW ? ` <span class="workspace-name">[ ${v.WorkspaceName} ]</span>` : ''
      const t = utils.getTranslation(v.Title, locale)
      return t ? t.concat(aux) : aux
    },
    convertLevel (v) {
      let result = 'insular'
      let aux = v.HideComarcalWorkspace ? v.HideComarcalWorkspace.split(',') : []
      if (aux.indexOf(this.workspaceID) >= 0) result = 'comarcal'
      aux = v.HideLocalWorkspace ? v.HideLocalWorkspace.split(',') : []
      if (aux.indexOf(this.workspaceID) >= 0) result = 'local'
      return result
    },
    handleGetData () {
      api.getAllWithoutLimit ('turismo', `v1/public/turismo-local/${this.id}/childrens`)
        .then(response => {
          this.menu = response.map(item => {
            item.HideWorkspaces = item.HideWorkspaces ? JSON.parse(item.HideWorkspaces) : []
            const aux = item.HideWorkspaces && item.HideWorkspaces.filter(x => x.WorkspaceID === this.workspaceID).shift()
            item.HideWorkspace = aux && aux.Hide === true ? true : false
            item.Title = item.Title ? JSON.parse(item.Title) : null
            item.Description = item.Description ? JSON.parse(item.Description) : null
            item.Service = item.Service ? JSON.parse(item.Service) : null
            item.Level = this.convertLevel(item)
            return item
          }).filter(x => (!x.MenuCheckWorkspaces || x.MenuCheckWorkspaces.indexOf(this.workspaceID) === -1) && ((!x.Hide && !x.HideWorkspace) || this.showAll))
        })
    },
    handleUpdate () {
      this.handleGetData()
    },
    handleHide (id, v) {
      api.updateItem ('turismo', `v1/public/turismo-local/`, `${id}/hide`, { Hide: v })
        .then(() => { 
          this.handleGetData() 
        })
    },
    handleHideWorkspace (item) {
      if(item.Hide) return
      item.HideWorkspace = !item.HideWorkspace
      const body = { 
        WorkspaceID: this.workspaceID, 
        Hide: item.HideWorkspace 
      }
      api.updateItem ('turismo', `v1/public/turismo-local/`, `${item.ID}/hide-workspace`, body)
        .then(() => { 
          this.handleGetData() 
        })
    },
    handleSortItems (v) {
      for (let i = 0; i < v.length; i++) v[i].ORD = i
      
      const body = v.map(item => {
        return {
          ID: item.ID,
          ORD: item.ORD,
        }
      })
      api.updateItem ('turismo', `v1/public/turismo-local/`, `sort`, body)
        .then(() => { 
          this.handleGetData() 
        })
    },
    handleSortStart (v) {
      this.activeDraggable = v
    },
    handleCopyID (v) {
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(v)

      /* Alert the copied text */
      alert("Copied the text: " + v)
    },
    handleChangeLevel (id, v) {
      const body = { 
        WorkspaceID: this.workspaceID, 
        Level: v
      }
      api.updateItem ('turismo', `v1/private/local/`, `${id}/level-workspace`, body)
        .then(() => { 
          this.handleGetData() 
        })
    },
  }
}
</script>
<style>
.add-menu {
  height: 100%;
  vertical-align: middle;
  cursor: pointer;
}
.turismo .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.workspace-name {
  font-size: 0.7rem !important;
  opacity: 0.5;
}
</style>

