<template>
  <v-layout wrap v-if="permissions">
    <v-flex xs8 style="padding: 0px 10px 20px 20px;">
      <v-layout wrap>
        <v-flex xs6>
          <v-checkbox :label="$t('turismo.showAll', locale)" v-model="showAll" />
        </v-flex>
        <v-flex xs6 style="padding: 15px 0;text-align:right;">
          <v-spacer />
          <v-btn @click="handleResult"
            color="primary"
          >
            {{ $t('turismo.show', locale) }}
          </v-btn>
        </v-flex>
      </v-layout>
      <!--small v-if="showPermissions">
        {{permissions}}
      </small-->
      <menus
        :id="main"
        :updateData="updateData"
        :showAll="showAll" 
        :onAddMenu="handleAddMenu"
        :onEdit="handleEdit"
        :onRequire="handleRequire"
        style="height:calc(100vh - 150px);overflow-y: auto;overflow-x: hidden;scrollbar-width: thin !important;"
        :permissions="permissions"
        :workspaceID="workspaceID"
        :locale="locale"
      />
    </v-flex>
    <v-flex xs4 style="padding: 20px 20px 20px 10px">
      <v-card
        style="min-height:calc(100vh - 104px);"
      >
        <v-card-text>
          <no-edited
            v-if="!editedItem"
            :locale="locale"
            :onAddMenu="handleAddMenu"
            :permissions="permissions"
            :workspaceID="workspaceID"
          />
          <menu-form 
            v-else-if="editedItem"
            :item="editedItem" :onUpdate="handleUpdate" 
            :onClose="handleClose"
            :permissions="permissions"
            :workspaceID="workspaceID"
          />
          <!--type-step
            v-else-if="!isMenuForm && editedID === 'new' && !newSelectedContentType"
            :locale="locale"
            :newParentID="editedParentID"
            :onClose="handleCloseForm"
            :onAddSubmenu="handleAddSubmenu"
            :onSelectContentType="handleSelectContentType"
          />
          <form-menu
            v-else-if="isMenuForm"
            :editedID="editedID"
            :newParentID="editedParentID"
            :config="config"
            :onClose="handleCloseForm"
            :isClone="isClone"
          />
          <form-content
            v-else-if="contentTypes"
            :editedID="editedID"
            :newParentID="editedParentID"
            :newSelectedContentType="newSelectedContentType"
            :config="config"
            :onClose="handleCloseForm"
            :contentTypes="contentTypes"
            :isClone="isClone"
          /-->
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import NoEdited from './NoEdited'
import Menus from './Menus'
import MenuForm from './MenuForm'
export default {
  components: {
    NoEdited,
    Menus,
    MenuForm,
  },
  data: () => ({
    editedItem: null,
    updateData: null,
    permissions: null,
    main: 'aa5dd237-9e2a-401c-8b7c-0866e9486428',
    showAll: false,
    defaultItem: {
      ID: null,
      Title: {
        es: '',
        en: '',
        fr: '',
        de: ''
      },
      Description: {
        es: '',
        en: '',
        fr: '',
        de: ''
      },
      ListDetailChildrens: false,
      GoToDetailDirect: false,
      CustomPage: null,
    }
  }),
  computed: {
    ...mapState('app',['locale', 'turismoWorkspace']),
    workspaceID () {
      return this.turismoWorkspace
    },
    showPermissions () {
      return this.$route.query.debug
    },
  },
  watch: {
    'turismoWorkspace' () {
      this.handleGetPermissions()
    },
  },
  mounted () {
    this.handleGetPermissions()
  },
  methods: {
    handleGetPermissions () {
      api.getAllWithoutLimit ('turismo', `v1/internal/workspaces/${this.workspaceID}/permissions`)
        .then(response => {
          const aux = response.shift()
          this.permissions = aux && aux.Permissions ? JSON.parse(aux.Permissions) : null
        })
    },
    handleClose (action, updateItem) {
      if (action && updateItem)
        this.updateData = {
          action,
          id: updateItem.CustomParentID,
          ids: [updateItem.CustomParentID],
          lmd: new Date().getTime()
        }
      this.editedItem = null
    },
    handleAddMenu (parentID) {
      if (!parentID) return
      this.editedItem = JSON.parse(JSON.stringify(this.defaultItem))
      this.editedItem.CustomParentID = parentID
      this.editedItem.WorkspaceID = this.workspaceID
    },
    handleEdit (v) {
      if (!v) return
      this.editedItem = v
    },
    handleRequire(v) {
      v.Required = !v.Required
      api.updateItem ('turismo', `v1/private/menu-content/`, `${v.ID}/require`, v)
        .then(response => {
          this.updateData = {
            action: 'require',
            ids: response.data.updatedIDs,
            lmd: new Date().getTime()
          }
        })

    },
    handleUpdate () {
      window.location.reload()
    },
    handleResult () {
      const url = process.env.VUE_APP_URL_TURISMO.concat('?of=', this.workspaceID)
      const win = window.open(url, '_blank')
      win.focus()
    },
  },
}
</script>

