<template>
      <v-card v-if="menu">
        <v-toolbar
          dark
          color="primary"
          :elevation="0"
        >
          <v-toolbar-title>{{ !menu.ID ? 'Crear men�' : `Editar "${item.Title.es}"`  }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-layout wrap>
          <v-flex xs5 style="text-align:right;padding:22px 10px 0 10px">
              <small class="v-label theme--light">HTML</small>
          </v-flex>
          <v-flex xs7 style="text-align:center">
              <v-switch
                v-model="menu.DescriptionRichText"
                hide-details
                label="richText"
              />
          </v-flex>
          <v-flex xs6 style="padding: 0 20px">
            <v-checkbox v-model="menu.ListDetailChildrens" label="Lista" hide-details />
            <v-checkbox v-model="menu.GoToDetailDirect" label="Directo a detalle" hide-details />
            <v-text-field
              v-if="menu.GoToDetailDirect && workspaceID === 'f0550a87-3358-4876-bf24-0ac33e9a93cf'"
              label="Componente personalizado"
              outlined
              v-model="menu.CustomPage"
              hide-details
              style="margin-top: 20px;"
            />
          </v-flex>
          <v-flex xs6 style="padding: 0 20px">
            <v-checkbox v-model="menu.ForceOrder" label="Ordenar contenido alfab�ticamente" hide-details />
          </v-flex>
          <v-flex xs12 style="padding: 10px 20px 0 20px">
            <v-text-field
              id="customParentID"
              v-if="isDebug"
              label="ParentID"
              outlined
              color="red"
              v-model="menu.CustomParentID"
              hide-details
            />
          </v-flex>
          <v-flex xs12 style="padding: 10px 30px">
            <translate
              v-model="menu.Title"
              :languages="languages"
              :label="$t('booking.name', locale)"
              @input="handleChangeIsModified"
              type="text"
            />
          </v-flex>
          <v-flex xs12 style="padding: 20px 30px 0 30px" 
          >
            <translate
              :key="menu.DescriptionRichText"
              v-model="menu.Description"
              :languages="languages"
              :label="$t('booking.description', locale)"
              @input="handleChangeIsModified"
              :type="menu.DescriptionRichText ? 'richText' : 'textArea'"
            />
          </v-flex>
          <!--v-flex xs12 style="padding: 10px 30px 0 30px" v-if="menu.DescriptionRichText">
            <small calls="label">{{ $t('booking.description', locale) }}</small>
            <translate
              v-model="menu.Description"
              :languages="languages"
              :label="$t('booking.description', locale)"
              @input="handleChangeIsModified"
              type="richText"
            />
          </v-flex-->
          <v-flex xs12 style="padding: 20px 30px">
            <small calls="label">{{ $t('turismo.image', locale) }}</small>
            <select-image
              style="margin-top:0px"
              :image="image"
              folder="turismo"
              :onChange="handleChangeImage"
            />
          </v-flex>
          
          <v-flex xs12 style="padding: 10px 30px">
            <translate
              v-model="menu.Service"
              :languages="languages"
              :label="$t('turismo.services', locale)"
              @input="handleChangeIsModified"
              type="textArea"
            />
          </v-flex>

          <v-flex xs6 style="padding: 10px 30px">
            <v-text-field
              v-model="menu.Latitude"
              :label="$t('turismo.latitude', locale)"
              @input="handleChangeIsModified"
              type="text"
              hide-details
            />
          </v-flex>
          <v-flex xs6 style="padding: 10px 30px">
            <v-text-field
              v-model="menu.Longitude"
              :label="$t('turismo.longitude', locale)"
              @input="handleChangeIsModified"
              type="text"
              hide-details
            />
          </v-flex>

          <v-flex xs12 style="padding: 10px 30px">
            <v-text-field
              v-model="menu.Email"
              :label="$t('turismo.email', locale)"
              @input="handleChangeIsModified"
              type="text"
              hide-details
            />
          </v-flex>

          <v-flex xs12 style="padding: 10px 30px">
            <v-text-field
              v-model="menu.Address"
              :label="$t('turismo.address', locale)"
              @input="handleChangeIsModified"
              type="text"
              hide-details
            />
          </v-flex>

          <v-flex xs12 style="padding: 10px 30px">
            <v-text-field
              v-model="menu.Phone"
              :label="$t('turismo.phone', locale)"
              @input="handleChangeIsModified"
              type="text"
              hide-details
            />
          </v-flex>

          <v-flex xs12 style="padding: 10px 30px">
            <v-text-field
              v-model="menu.Timetable"
              :label="$t('turismo.timetable', locale)"
              @input="handleChangeIsModified"
              type="text"
              hide-details
            />
          </v-flex>
          <v-flex xs12 style="padding: 10px 30px">
            <v-text-field
              v-model="menu.Web"
              :label="$t('turismo.web', locale)"
              @input="handleChangeIsModified"
              type="text"
              hide-details
            />
          </v-flex>

          <v-flex xs12>
            <!--action-buttons
              :id="editedItem.ID"
              :onDeleteItem="handleDelete"
              :onSaveItem="handleSave"
              :onClose="onClose"
              :locale="locale"
            /-->
            <action-buttons
              :id="menu.ID"
              :onDeleteItem="showDelete ? (v) => { handleDelete(v) }:null"
              :onSaveItem="handleSave"
              :onClose="onClose"
              :locale="locale"
            />
          </v-flex>
        </v-layout>
      </v-card>
</template>
<script>
import api from '@/services/api'
import Translate from '@/components/Translate'
import ActionButtons from '@/components/ActionButtons'
import SelectImage from '@/components/selectImage/Index'
export default {
  components: {
    Translate,
    ActionButtons,
    SelectImage,
  },
  props: {
    newItemParentID: {
      type: String,
      default: null,
    },
    item: {
      type: Object,
      required: {},
    },
    onClose: {
      type: Function,
      default: null,
    },
    onUpdate: {
      type: Function,
      required: true,
    },
    workspaceID: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      showDelete: false,
      image: null,
      locale: 'es',
      languages: [ 'es', 'en', 'de', 'fr'],
      dialog: false,
      menu: null,
      notifications: false,
      sound: true,
      widgets: false,
    }
  },
  computed: {
    isDebug () {
      return this.$route.query.debug
    },
  },
  watch: {
    item () {
      this.prepareData()
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      this.showDelete = this.item.ID !== null
      //this.menu = this.item //
      this.menu = JSON.parse(JSON.stringify(this.item))
      this.image = this.menu.Image
    },
    handleChangeIsModified () {

    },
    handleChangeImage (v) {
      if  (v && v.base64) this.image = v.base64
      this.menu.newImage = v
    },
    handleDelete (v) {
      api.deleteItem('turismo', `v1/private/menu-content/`, v)
        .then(() => {
          this.onClose('delete', this.menu)
        })
    },
    handleSave () {
      if (this.menu.ID) {
        api.updateItem('turismo', `v1/private/menu-content/`, this.menu.ID, this.menu)
          .then(() => {
            this.onClose('update', this.menu)
          })
      } else {
        if (this.menu.CustomParentID)
          api.addItem('turismo', `v1/private/menu-content/`, this.menu)
            .then(() => {
              this.dialog = false
              this.onClose('add', this.menu)
            })
      }
    },
  },
}
</script>


